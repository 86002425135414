// We need to check the inline ce images here for correct width and height
import Image from 'next/image';
import { Link, Container } from '@mui/material';

import type { AssetFile } from 'contentful';
import type { ICeEmbeddedImageFields } from 'types/generated/contentful';
export interface EmbeddedImageProps extends ICeEmbeddedImageFields {
  isInline?: boolean;
  priority?: boolean;
}
export const EmbeddedImage = (props: EmbeddedImageProps) => {
  const {
    source,
    width,
    height,
    float = 'none',
    altText,
    link,
    isInline = false,
    priority = false,
  } = props;

  const file = source.fields.file as AssetFile;
  const url = file.url.toString();
  const displayContainer = !isInline;

  const style = {
    float,
    marginLeft: float === 'right' ? '1rem' : '0',
    marginRight: float === 'left' ? '1rem' : '0',
    marginTop: '.5rem',
    marginBottom: '.5rem',
  };

  // We are falling back if the enttries are missing
  const originalWidth = file.details!.image!.width;
  const originalHeight = file.details!.image!.height;

  const actualWidth = width || originalWidth || 1;
  let actualHeight = height || originalHeight || 1;

  if (width && !height) {
    actualHeight = (originalHeight / originalHeight) * width;
  }

  if (displayContainer)
    return (
      <Container maxWidth="lg">
        {link ? (
          <Link href={link} target="_blank" rel="noopener">
            <Image
              alt={altText}
              src={url}
              width={actualWidth}
              height={actualHeight}
              style={style}
              loading="lazy"
              priority={priority}
            />
          </Link>
        ) : (
          <Image
            alt={altText}
            src={url}
            width={actualWidth}
            height={actualHeight}
            style={style}
            loading="lazy"
            priority={priority}
          />
        )}
      </Container>
    );

  // Embedded version in richtext

  return (
    <>
      {link ? (
        <Link href={link} target="_blank" rel="noopener">
          <Image
            alt={altText}
            src={url}
            width={actualWidth}
            height={actualHeight}
            style={style}
            loading="lazy"
            priority={priority}
          />
        </Link>
      ) : (
        <Image
          alt={altText}
          src={url}
          width={actualWidth}
          height={actualHeight}
          style={style}
          loading="lazy"
          priority={priority}
        />
      )}
    </>
  );
};
